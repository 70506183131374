import { api } from './request.js'

//初始数据
// export function apiSiteInfo(){
//     return api('platform/siteInfo');
// }

//获取code
// export function apiCode(){
//     return api('platform/account/getCode');
// }

//登录
export function apiLogin(data){
    return api('platform/account/pwdLogin',data);
}

//
export function apiLayout(){
    return api('platform/account/layout');
}
//创建新账号
export function apiCreateShop(data){
    return api('platform/account/createShop',data);
}

//账号列表
export function apiAccountList(data){
    return api('platform/account/accountList',data);
}


